import * as React from "react";
import { graphql, HeadFC, Link, PageProps } from "gatsby";
import { Seo } from "components/Seo";
import { Layout } from "components/Layout";

const PostListByHajimeNakagawaPage: React.FC<
  PageProps<Queries.PostListByAuthorMatsuoQuery>
> = ({ data }) => {
  return (
    <Layout>
      <h1 className="text-3xl font-bold underline">松尾俊弥</h1>
      <ul>
        {data.allMicrocmsPosts.nodes.map((node) => {
          return (
            <li key={node.postsId}>
              <Link to={`/post/${node.postsId}`}>
                <span>{node.title}</span>
                <time>（公開日：{node.publishedAt}）</time>
              </Link>
            </li>
          );
        })}
      </ul>
    </Layout>
  );
};

export const query = graphql`
  query PostListByAuthorMatsuo {
    allMicrocmsPosts(filter: { author: { eq: "松尾俊弥" } }) {
      nodes {
        postsId
        publishedAt(formatString: "YYYY/MM/DD")
        title
      }
    }
  }
`;

export default PostListByHajimeNakagawaPage;

export const Head: HeadFC = () => <Seo title="松尾俊弥の投稿一覧" />;
